// return top btn
// ========
import debounce from 'lodash/debounce'

class ReturnTop {
  constructor(elem) {
    this.elem = elem
    this.init()
  }

  init() {
    const scroll = () => {
      let e = window.pageYOffset ? window.pageYOffset : 0
      e <= 400 ? this.elem.classList.remove('is-displayed') : this.elem.classList.add('is-displayed')
    }

    window.addEventListener("scroll", debounce(scroll, 150))
  }
}

[...document.querySelectorAll('.crtReturnTop')].map(elem => elem.top = new ReturnTop(elem))
